import React from 'react';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import SEO from '../components/SEO/SEO';

const ArticleContainer = styled.article`
  max-width: 800px;
  position: relative;
  top: -2rem;
  margin: 0 auto;
  background-color: #fcfbfc;

  ul {
    margin: 1rem 0;
    padding: 0.5rem 1.5rem;
    list-style: none;
    font-size: 0.9rem;
    background-color: #fff;
    border-left: 4px solid rgba(0, 120, 255, 0.3);
    li {
      :before {
        content: '- ';
      }
      padding: 0.2rem 0;
      margin: 0;
    }
  }
`;

const ArticleH2 = styled.h2`
  display: block;
  font-size: calc(2rem + 2vw);
  padding: 1rem;
  margin-bottom: 0;
`;
const ArticleDate = styled.p`
  padding: 0 1rem;
  margin-bottom: 0.2rem;
  font-size: 0.8rem;
`;
const ArticleContent = styled.div`
  p {
    padding: 0 1rem;
  }
  p span {
    margin: 0 !important;
  }
  h3#additional-images ~ p {
    padding: 0;
  }
  h3,
  h4 {
    margin: 0.5rem;
  }
`;

export default ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt || data.siteMetadata.description}
        pathname={post.fields.slug}
        article
        image={
          post.frontmatter.coverimage !== null
            ? `/assets/${post.frontmatter.coverimage.relativePath}`
            : null
        }
      />
      <ArticleContainer>
        {post.frontmatter.coverimage !== null ? (
          <Img
            fluid={post.frontmatter.coverimage.childImageSharp.fluid}
            alt={`Cover image for ${post.frontmatter.title}`}
          />
        ) : null}
        <ArticleH2>{post.frontmatter.title}</ArticleH2>
        {post.frontmatter.posttype !== 'Project' ? (
          <ArticleDate>
            <time>Posted: {post.frontmatter.date}</time>
          </ArticleDate>
        ) : null}
        {post.frontmatter.posttype !== 'Project' && post.frontmatter.updated !== 'Invalid date' ? (
          <ArticleDate style={{ marginBottom: '1rem' }}>
            <time>Last Updated: {post.frontmatter.updated}</time>
          </ArticleDate>
        ) : null}
        <ArticleContent>
          <div style={{ padding: '0 1rem 2rem' }} dangerouslySetInnerHTML={{ __html: post.html }} />
        </ArticleContent>
      </ArticleContainer>
    </Layout>
  );
};

export const query = graphql`
  query PostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      html
      excerpt(pruneLength: 150)
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        updated(formatString: "DD MMMM, YYYY")
        posttype
        description
        coverimage {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
